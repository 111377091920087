.question-list {
  display: grid;
  gap: 4px 4px;
  grid-template-columns: repeat(5, 1fr);
}


.question-item {
  border-radius: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 35px;
  padding: 2px;
  background: #fff;
  border: 2px solid #abb6bb;
  color: #000;
  text-align: center;
  font: 15px Arial, sans-serif;
  cursor: pointer;
}
.question-container {
  opacity: 0;
  transition: opacity 0.3s ease-out;
}

.question-container.show {
  opacity: 1;
}
.question-item:hover {
  background-color: rgb(142, 241, 233);
}

.question-item.current-question-color {
  border: 3px solid blue;
}

.question-item.answered {
  background-color: rgb(188, 236, 188);
}

.question-item.unanswered {
  background-color: red;
}

.subjectheader {
  font-size: 30px;
}

.flag {
  font: 20px Arial, sans-serif;
}

.timer {
  font: 20px Arial, sans-serif;
}

.questionnumbercolor {
  background-color: rgb(243, 245, 132);
}

.pagination {
  display: flex;
}
.block-math {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  grid-gap: 2px; 
}
img {

  max-width: 100%;
  opacity: 1;
  height: 200px;

}

.pagination a {
  color: black;
  backface-visibility: hidden;
  float: center;
  padding: 10px 16px;
  text-decoration: none;
}

.pagination a.active {
  background-color: #4CAF50;
  color: white;
}

.current-question {
  font: 18px Arial, sans-serif
}

.buttons {
  padding-right: 150px
}
