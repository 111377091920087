body {
  background-color: lightgrey;
  background: #eef4fb;
  min-height: 100vh;
  margin: 0;
  font-family: 'Inter', sans-serif; 
  
  font-size: 14px;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Footer CSS Start */
/* ==== Footer ==== */
footer a {
  color: #007BFF; /* Standard blue for links */
  text-decoration: none;
  font-weight: bold;
}

footer a:hover {
  color: #0056b3; /* Slightly darker blue on hover */
  text-decoration: underline;
}
footer {
  background: #007BFF;
  color: #fff;
  padding: 30px 20px;
  text-align: center;
}
footer .footer-links {
  margin-bottom: 10px;
}
footer .footer-links a {
  color: #fff;
  font-size: 0.9em;
  margin: 0 10px;
  transition: color 0.3s;
}
footer .footer-links a:hover { color: #FFD700; }
footer p { font-size: 0.8em; }
.decorative-graduation {
  position: absolute;
  top: 10%;
  right: 10%;
  z-index: 2;
  opacity: 0.9;
  width: 48px;
  height: 48px;
}
a {
  color: #007BFF;
  text-decoration: none;
  font-weight: bold;
}
a:hover {
  color: #0056b3;
  text-decoration: underline;
}
/* Footer CSS End */

@import "tailwindcss";
